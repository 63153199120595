<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Crear Puntos de Gestión</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">
                    Crear puntos de gestión
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <!-- /.card-header -->
            <form
              @submit="savePuntoGestion()"
              onsubmit="return false"
              class="form-letter mt-0 mb-5 mb-md-5 align-items-center"
            >
              <div class="card">
                <div class="card-body">
                  <!-- Formulario  -->
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label>Nombre</label>
                      <input
                        type="text"
                        v-model="nombre"
                        id="nombre"
                        class="form-control form-control-sm"
                        :class="$v.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Ciudad</label>
                      <v-select
                        :class="[
                          $v.ciudad.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="ciudad"
                        placeholder="Ciudades"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="ciudades"
                        :filterable="true"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Dirección</label>
                      <input
                        type="text"
                        v-model="direccion"
                        class="form-control form-control-sm"
                        :class="
                          $v.direccion.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>Teléfono</label>
                      <input
                        type="number"
                        v-model="telefono"
                        class="form-control form-control-sm"
                        :class="
                          $v.telefono.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Longitud</label>
                      <input
                        type="number"
                        v-model="longitud"
                        step="any"
                        class="form-control form-control-sm"
                        :class="
                          $v.longitud.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Latitud</label>
                      <input
                        type="number"
                        v-model="latitud"
                        step="any"
                        class="form-control form-control-sm"
                        :class="$v.latitud.$invalid ? 'is-invalid' : 'is-valid'"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Capacidad parqueo</label>
                      <input
                        type="number"
                        v-model="capacidad"
                        class="form-control form-control-sm"
                        :class="
                          $v.capacidad.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label>URL Video (YouTube)</label>
                      <input
                        type="text"
                        v-model="url"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <!-- Pestañas de asignaciones  -->
                  <div class="row">
                    <div class="form-group col-md-6">
                      <a
                        class="btn btn-block btn-secondary"
                        data-toggle="collapse"
                        href="#tipos"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Tipo punto gestión
                      </a>
                      <div class="collapse" id="tipos">
                        <div class="card card-body">
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="tipo"
                                label="descripcion"
                                :options="tipos"
                                placeholder="Tipos"
                                @input="addTipo"
                              ></v-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(row, index) in tipoSelected"
                                    :key="index"
                                  >
                                    <td>
                                      {{ row.descripcion }}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="
                                          deleteItem('tipoSelected', row.id)
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <a
                        class="btn btn-block btn-secondary"
                        data-toggle="collapse"
                        href="#productos"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Productos
                      </a>
                      <div class="collapse" id="productos">
                        <div class="card card-body">
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="producto"
                                label="descripcion"
                                :options="productos"
                                placeholder="Productos"
                                @input="addProducto"
                              ></v-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(row, index) in productoSelected"
                                    :key="index"
                                  >
                                    <td>
                                      {{ row.descripcion }}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="
                                          deleteItem('productoSelected', row.id)
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <a
                        class="btn btn-block btn-secondary"
                        data-toggle="collapse"
                        href="#cara"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Procesos
                      </a>
                      <div class="collapse" id="cara">
                        <div class="card card-body">
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="proceso"
                                label="descripcion"
                                :options="procesos"
                                placeholder="Procesos"
                                @input="addProceso"
                              ></v-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(row, index) in procesoSelected"
                                    :key="index"
                                  >
                                    <td>
                                      {{ row.descripcion }}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="
                                          deleteItem('procesoSelected', row.id)
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <a
                        class="btn btn-block btn-secondary"
                        data-toggle="collapse"
                        href="#inspe"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Inspecciones
                      </a>
                      <div class="collapse" id="inspe">
                        <div class="card card-body">
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="inspeccion"
                                label="nombre"
                                :options="inspecciones"
                                placeholder="Inspecciones"
                                @input="addInspeccion"
                              ></v-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(row, index) in inspeccionSelected"
                                    :key="index"
                                  >
                                    <td>
                                      {{ row.nombre }}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="
                                          deleteItem(
                                            'inspeccionSelected',
                                            row.id
                                          )
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <a
                        class="btn btn-block btn-secondary"
                        data-toggle="collapse"
                        href="#sitios"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Sitios Cargue Descargue
                      </a>
                      <div class="collapse" id="sitios">
                        <div class="card card-body">
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="sitio"
                                label="nombre"
                                :options="sitios"
                                placeholder="Sitios"
                                @input="addSitio"
                              ></v-select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <!-- @change="findFilterBar($event)" -->
                              <table
                                class="table table-bordered table-striped table-hover table-sm"
                              >
                                <thead>
                                  <tr>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(row, index) in sitioSelected"
                                    :key="index"
                                  >
                                    <td>
                                      {{ row.nombre }}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        @click="
                                          deleteItem('sitioSelected', row.id)
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Mensaje de alerta -->
                  <div v-show="flagAlert" class="row">
                    <div class="col-md-12 text-center">
                      <div class="alert alert-danger" role="alert">
                        Por favor seleccione todas las opciones
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <button
                      class="btn bg-secondary col-md-1"
                      @click="back()"
                      v-if="$store.getters.can('admin.puntosGestion.index')"
                    >
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      type="submit"
                      class="btn bg-primary col-md-1"
                      v-if="
                        $store.getters.can('admin.puntosGestion.create') &&
                          !$v.nombre.$invalid &&
                          !$v.ciudad.$invalid &&
                          !$v.estado.$invalid &&
                          !$v.telefono.$invalid &&
                          !$v.longitud.$invalid &&
                          !$v.latitud.$invalid &&
                          !$v.capacidad.$invalid
                      "
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import serverConfig from "./../../../../services/config";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "PuntosCreate",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      flagAlert: false,
      cargando: false,
      nombre: "",
      ciudad: "",
      direccion: "",
      estado: 1,
      telefono: "",
      longitud: "",
      latitud: "",
      capacidad: "",
      url: "",
      tipo: "",
      tipos: [],
      tipoSelected: [],
      tipoSelectedId: [],
      producto: "",
      productos: [],
      productoSelected: [],
      productoSelectedId: [],
      proceso: "",
      procesos: [],
      procesoSelected: [],
      procesoSelectedId: [],
      sitio: "",
      sitioSelected: [],
      sitioSelectedId: [],
      inspeccion: "",
      inspeccionSelected: [],
      inspeccionSelectedId: [],
    };
  },
  beforeMount() {
    this.actGetCiudades();

    this.actGetListas(19).then(() => {
      this.tipos = this.listas;
      this.actGetListas(20).then(() => {
        this.productos = this.listas;
        this.actGetListas(21).then(() => {
          this.procesos = this.listas;
        });
      });
    });

    this.actGetInspeccion();
    this.actGetSitios();
  },
  validations: {
    nombre: {
      required,
    },
    ciudad: {
      required,
    },
    direccion: {
      required,
    },
    estado: {
      required,
    },
    telefono: {
      required,
    },
    longitud: {
      required,
    },
    latitud: {
      required,
    },
    capacidad: {
      required,
    },
  },
  computed: {
    ...mapState("modOperacion", [
      "ciudades",
      "listas",
      "sitios",
      "loading",
      "inspecciones",
    ]),
  },
  methods: {
    ...mapActions("modOperacion", [
      "actGetCiudades",
      "actGetListas",
      "actGetSitios",
      "actSavePuntoGestion",
      "actGetInspeccion",
    ]),

    addTipo() {
      //alert(JSON.stringify(this.tipo));
      for (let i = 0; i < this.tipos.length; i++) {
        if (this.tipos[i].id == this.tipo.id) {
          if (
            this.tipoSelected.findIndex((x) => x.id === this.tipo.id) === -1
          ) {
            this.tipoSelected.push(this.tipos[i]);
            this.tipoSelectedId.push(this.tipos[i].id);
          }
        }
      }
    },

    addProducto() {
      //alert(JSON.stringify(this.tipo));
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id == this.producto.id) {
          if (
            this.productoSelected.findIndex(
              (x) => x.id === this.producto.id
            ) === -1
          ) {
            this.productoSelected.push(this.productos[i]);
            this.productoSelectedId.push(this.productos[i].id);
          }
        }
      }
    },

    addProceso() {
      //alert(JSON.stringify(this.tipo));
      for (let i = 0; i < this.procesos.length; i++) {
        if (this.procesos[i].id == this.proceso.id) {
          if (
            this.procesoSelected.findIndex((x) => x.id === this.proceso.id) ===
            -1
          ) {
            this.procesoSelected.push(this.procesos[i]);
            this.procesoSelectedId.push(this.procesos[i].id);
          }
        }
      }
    },

    addInspeccion() {
      //alert(JSON.stringify(this.tipo));
      for (let i = 0; i < this.inspecciones.length; i++) {
        if (this.inspecciones[i].id == this.inspeccion.id) {
          if (
            this.inspeccionSelected.findIndex(
              (x) => x.id === this.inspeccion.id
            ) === -1
          ) {
            this.inspeccionSelected.push(this.inspecciones[i]);
            this.inspeccionSelectedId.push(this.inspecciones[i].id);
          }
        }
      }
    },

    addSitio() {
      //alert(JSON.stringify(this.tipo));
      for (let i = 0; i < this.sitios.length; i++) {
        if (this.sitios[i].id == this.sitio.id) {
          if (
            this.sitioSelected.findIndex((x) => x.id === this.sitio.id) === -1
          ) {
            this.sitioSelected.push(this.sitios[i]);
            this.sitioSelectedId.push(this.sitios[i].id);
          }
        }
      }
    },

    deleteItem(item, id) {
      switch (item) {
        case "tipoSelected":
          this.tipoSelected = this.tipoSelected.filter(
            (item) => item.id !== id
          );
          this.tipoSelectedId = this.tipoSelectedId.filter(
            (item) => item !== id
          );
          break;
        case "productoSelected":
          this.productoSelected = this.productoSelected.filter(
            (item) => item.id !== id
          );
          this.productoSelectedId = this.productoSelectedId.filter(
            (item) => item !== id
          );
          break;
        case "procesoSelected":
          this.procesoSelected = this.procesoSelected.filter(
            (item) => item.id !== id
          );
          this.procesoSelectedId = this.procesoSelectedId.filter(
            (item) => item !== id
          );
          break;
        case "inspeccionSelected":
          this.inspeccionSelected = this.inspeccionSelected.filter(
            (item) => item.id !== id
          );
          this.inspeccionSelectedId = this.inspeccionSelectedId.filter(
            (item) => item !== id
          );
          break;
        case "sitioSelected":
          this.sitioSelected = this.sitioSelected.filter(
            (item) => item.id !== id
          );
          this.sitioSelectedId = this.sitioSelectedId.filter(
            (item) => item !== id
          );
          break;
      }
    },

    back() {
      return this.$router.replace("/Admin/PuntosGestion");
    },

    savePuntoGestion() {
      //if(this.tipoSelectedId.length>0 && this.productoSelectedId.length>0 && this.procesoSelectedId.length>0 && this.inspeccionSelectedId.length>0 && this.sitioSelectedId .length>0){
      this.flagAlert = false;
      let data = [];
      data = {
        nombre: this.nombre,
        ciudad_id: this.ciudad.id,
        direccion: this.direccion,
        telefono: this.telefono,
        observacion: "",
        estado: this.estado,
        latitud: this.latitud,
        longitud: this.longitud,
        capacidad_parqueo: this.capacidad,
        link_video: this.url,
        tipos: this.tipoSelectedId,
        tipos_productos: this.productoSelectedId,
        procesos: this.procesoSelectedId,
        inspecciones: this.inspeccionSelectedId,
        sitios: this.sitioSelectedId,
      };
      //alert(JSON.stringify(data));
      this.cargando = true;
      this.actSavePuntoGestion(data)
        .then(() => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "El punto de gestion se creó con exito",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$router.push({ path: "/Admin/PuntosGestion" });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
<!--
<style lang="scss">
[v-cloak] {
  display: none;
}
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .view {
  display: none;
}
</style>
-->
